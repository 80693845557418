<template>
	<div class="container-fluid">
		<div class="row justify-content-center bg-secondary-light py-5" style="min-height: calc(100vh - 164px)">
			<div class="mb-3 col-lg-8">
				<div class="card">
					<div class="card-header bg-primary text-white">
						<div class="text-right">
							<div v-if="prenda.idusuario == user.idusuario">
								<router-link :to="{name:'perfil.mispublicaciones.autorizadas'}" v-if="prenda.estado == 'Autorizado'">
									<img src="@/assets/image/close_white.webp" class="img-fluid">
								</router-link>
								<router-link :to="{name:'perfil.mispublicaciones.denegadas'}" v-if="prenda.estado == 'Denegado'">
									<img src="@/assets/image/close_white.webp" class="img-fluid">
								</router-link>
								<router-link :to="{name:'perfil.mispublicaciones.espera'}" v-if="prenda.estado == 'En espera de autorización'">
									<img src="@/assets/image/close_white.webp" class="img-fluid">
								</router-link>
								<router-link :to="{name:'perfil.mispublicaciones.finalizadas'}" v-if="prenda.estado == 'Finalizado'">
									<img src="@/assets/image/close_white.webp" class="img-fluid">
								</router-link>
							</div>
							<div v-else>
								<router-link :to="{name:'opciones-inversion.index'}">
									<img src="@/assets/image/close_white.webp" style="width: 20px; height: 20px;">
								</router-link>
							</div>
						</div>
					</div>
					<div class="card-body py-4">
						<div class="row">
							<div class="col-lg-3 mb-3">
								<div class="h-100 d-flex align-items-center justify-content-center px-3">
									<div>
										<div class="px-4 pt-4 bg-primary overflow-hidden rounded mb-2" style="border-radius: 5px;">
											<img src="@/assets/image/usuarios.webp" class="img-fluid">
										</div>
										<h5 v-if="prenda.idusuario == user.idusuario" class="text-center font-weight-bold">
											{{prenda.nomusuario}} {{prenda.apellido}}
										</h5>
										<h5 v-else class="text-center font-weight-bold">
											{{prenda.aliasusuario}}
										</h5>
									</div>
								</div>
							</div>
							<div class="col-lg-9 mb-3">
								<div class="card bg-light">
									<div class="card-body">
										<div class="d-flex flex-column flex-lg-row justify-content-between">
											<div class="order-1 order-lg-0 mb-3">
												<h5 class="font-weight-bold">
													{{prenda.nombre}}
												</h5>
												<div>
													<b>Categoría:</b> {{prenda.categoria}}
												</div>
												<div>
													<b>Tipo: </b> {{prenda.tipo}}
												</div>
												<div>
													<b>Monto requerido:</b> {{$filters.currency(prenda.precio || 0)}}
												</div>
												<div>
													<b>Fecha de publicación:</b> {{prenda.fechaPublicada}}
												</div>
												<div>
													<b>Descripción:</b>
												</div>
											</div>	
											<div class="order-0 order-lg-1 mx-auto mx-lg-0 mb-3">
												<div style="width: 100px;" class="text-center">
													<img v-lazy="$filters.storage(fotoSeleccionada.ruta, 100)" class="rounded-circle" width="100" height="100" :alt="prenda.nombre">
													<br>
													<small class="font-weight-bold" v-if="prenda.idusuario == user.idusuario">
														{{prenda.estado}}
													</small>
												</div>
											</div>
										</div>
										<div class="text-justify white-space mt-3">{{prenda.descripcion}}</div>
										<br>
										<div class="text-right" v-if="prenda.idusuario == user.idusuario && prenda.estado != 'Finalizado' && prenda.estado !='Denegado'">
											<button type="button" v-on:click="destroy()" :disabled="loadingDelete" class="btn btn-inversion rounded-pill font-weight-bold px-3">
												Eliminar
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="" v-if="showPrerequisitos">
							<div class="card bg-light">
								<div class="card-body py-3">
									<h5 class="font-weight-bold">Pre requisitos</h5>
									<div class="text-center" v-if="loadingPrerequisitos">
										<BtnLoading />
									</div>
									<div v-for="(prerequisito, index) in prerequisitos" :key="index" v-else>
										<ul class="list-group">
											<li :class="{'list-group-item d-flex justify-content-between align-items-center':true, 'bg-light':prerequisito.LLENO==1}">
												<h5 class="mt-0" v-if="prerequisito.prerequisito">
													<span v-if="prerequisito.prerequisito.tipo_requisito" :class="{'text-muted line-through':prerequisito.LLENO == 1}">
														{{prerequisito.prerequisito.tipo_requisito.DESCRIPCION_PREREQUISITO}}
													</span>
												</h5>
												<div>
													<Upload :class="{'btn btn-sm':true, 'btn-outline-success':prerequisito.LLENO==1, 'btn-primary':prerequisito.LLENO!=1}" :name="'file'+prerequisito.CODIGO" v-on:fileChange="addPrerequisito($event, prerequisito.CODIGO)">
														<BtnLoading v-if="loadingFile[prerequisito.CODIGO]"/>
														<span v-else>
															<span class="material-icons" v-if="prerequisito.LLENO==1">check</span>
															<span class="material-icons" v-else>backup</span>
														</span>
													</Upload>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Upload = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Upload" */"@/components/Upload.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default {
		components: {
			Upload,
			BtnLoading
		},
		data(){
			return {
				loadingPublicacion: false,
				loadingPublicacionFotos: false,
				fotoSeleccionada: {},
				loadingDelete: false,
				loadingPrerequisitos: false,
				prerequisitos: [],
				loadingFile: []
			}
		},
		mounted(){
			this.getPublicacion()
			this.getPublicacionFotos()
			this.getPrerequisitos()
		},
		methods:{
			getPrerequisitos(){
				if (!this.prenda.codigo_oportunidad) {
					return
				}
				this.loadingPrerequisitos = true
				this.$store.dispatch('getPrerequisitos',{
					oportunidad: this.prenda.codigo_oportunidad
				}).then(response => {
					this.prerequisitos = response.data || []
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPrerequisitos = false
				})
			},
			getPublicacion(){
				this.loadingPublicacion = true
				this.$store.dispatch('getPublicacion',{
					idprenda: this.id,
					idusuario: this.user.idusuario || 0
				}).then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			getPublicacionFotos(){
				this.loadingPublicacion = true
				this.$store.dispatch('getPublicacionFotos',{
					idprenda: this.id
				}).then(response => {
					var fotos = response.data
					this.fotoSeleccionada = fotos[0] || {}
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			addPrerequisito(file, codigo){
				this.loadingFile[codigo] = true
				var formData = new FormData()
				var url = 'api/oportunidades/prerequisitos/'+codigo+'/portal'
				formData.append('file', file)
		        formData.append('url', url)
				this.$store.dispatch('addPrerequisito', formData).then(response => {
					window.toastr.info('El archivo ha sido subido.', 'Aviso')
					this.getPrerequisitos()
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFile[codigo] = false
				})
			},
			destroy(){
				if (this.prenda.idusuario != this.user.idusuario) {
					window.toastr.error('No tienes autorizado realizar esta acción.', 'Error')
					return
				}
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas eliminar esta publicación?`,
					buttons: {
						eliminar: function () {
							mod.publicacionUpdateStatus()
				    	},
				    	cancelar: function () {

				    	},
				    }
				})
			},
			publicacionUpdateStatus(){
				this.loadingDelete = true
				this.$store.dispatch('publicacionUpdateStatus',{
					idprenda: this.id,
					estado: 4
				}).then(response => {
					window.toastr.success("Cambio realizado con éxito.", "Éxito")
					if (this.prenda.estado == 'Autorizado') {
						this.$router.push({name: 'perfil.mispublicaciones.autorizadas'})
					}
					if(this.prenda.estado == 'Denegado'){
						this.$router.push({name: 'perfil.mispublicaciones.denegadas'})
					}
					if(this.prenda.estado == 'En espera de autorización'){
						this.$router.push({name: 'perfil.mispublicaciones.espera'})
					}
					if(this.prenda.estado == 'Finalizado'){
						this.$router.push({name: 'perfil.mispublicaciones.finalizadas'})
					}
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					this.loadingDelete = false
				})
			},
			selectFoto(val){
				this.fotoSeleccionada = val
			},
			trunc(x, posiciones = 0) {
				var s = x.toString()
				var l = s.length
				var decimalLength = s.indexOf('.') + 1
				if (l - decimalLength <= posiciones){
				  return x
				}
				var isNeg  = x < 0
				var decimal =  x % 1
				var entera  = isNeg ? Math.ceil(x) : Math.floor(x)
				var decimalFormated = Math.floor(
				  Math.abs(decimal) * Math.pow(10, posiciones)
				)
				var finalNum = entera + 
				  ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
				return finalNum
			},
		},
		computed:{
			id(){
				return this.$route.params.id || -1
			},
			publicacion(){
				return this.$store.getters.publicacion || []
			},
			prenda(){
				return this.publicacion[0] || {}
			},
			fotos(){
				return this.$store.getters.publicacionFotos || []
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			showPrerequisitos(){
				return this.prenda.estado == 'Autorizado' && this.prerequisitos.length>0
			},
			porcentajePrenda(){
				var montoRecaudado = this.prenda.montoRecaudado || 0
				var precio = this.prenda.precio || 1
				return this.trunc(((montoRecaudado*100)/precio), 2)
			},
			forms(){
				return {
					idusuario: this.user.idusuario,
					idprenda: this.id,
					fecha: Math.round(new Date().getTime()/1000.0)
				}
			}
		},
		watch:{
			'prenda':{
				handler(val){
					this.getPrerequisitos()
					return val
				},
				deep: true
			}
		}
	}
</script>