<template>
  <GoogleMap 
    api-key="AIzaSyCmjSHyubDd1ffe19XW6OTCW85wijnXUjw"
    :center="centerZoom"
    :zoom="zoomDF"
    @click="call">
    <Marker :options="{ position: center }" @click="call" v-if="center.lat!=0 || center.lng!=0" />
  </GoogleMap>
</template>
<script>
import { defineComponent } from 'vue'
import { GoogleMap, Marker } from 'vue3-google-map'
export default defineComponent({
  props: {
    lat: Number, 
    lng: Number,
    zoom: Number,
    disabled: Boolean
  },
  methods:{ 
    call(event){
      if ((this.disabled || false)) {return}
      this.form.lat = event.latLng.lat() || 0
      this.form.lng = event.latLng.lng() || 0
      this.$emit('update:modelValue', this.form)
    }
  },
  data(){
    return {
      form:{
        lat: 0,
        lng: 0
      }
    }
  },
  mounted(){
    this.form.lat = this.lat || 0
    this.form.lng = this.lng || 0
  },
  computed: {
    zoomDF(){
      return this.zoom || 15
    },
    latDF(){
      return this.lat || 0
    },
    lngDF(){
      return this.lng || 0
    },
    center(){
      return {
        lat: this.form.lat || 0,
        lng: this.form.lng || 0
      }
    },
    centerZoom(){
      return {
        lat: this.form.lat || 14.5984153,
        lng: this.form.lng || -90.5353151
      }
    }
  },
  watch:{
    latDF(val){
      this.form.lat = val
    },
    lngDF(val){
      this.form.lng = val
    },
  },
  components: { GoogleMap, Marker }
})
</script>