<template>
	<div class="container-fluid">
		<div class="row justify-content-center bg-secondary-light h-100" style="min-height: calc(100vh - 164px);">
			<div class="col-md-10 py-5">
				<div class="card overflow-hidden">
					<div class="card-header bg-secondary border-secondary">
						<h3 class="card-title text-center mb-0 font-weight-bold">Solicitud de préstamo</h3>
					</div>
					<div class="card-body bg-light">
						<form ref="userForm" v-on:submit.prevent="addPublicacion()" class="needs-validation was-validated" novalidate>
							<div class="row px-md-5">
								<div class="col-lg-5 align-items-center">
									<div class="row">
										<div class="col-md-12 mb-5">
											<input v-model="prenda.nombre" type="text" name="titulo" class="form-control" minlength="10" maxlength="100" placeholder="Título" required>
										</div>
										<div class="col-md-6 mb-5">
											<select v-model="prenda.idcategoria" name="categoria" class="custom-select" required>
												<option value="" disabled selected>Categoría</option>
												<option v-for="(categoria, index) in categorias" :value="categoria.id" :key="index">
													{{categoria.nombre}}
												</option>
											</select>
										</div>
										
										<div class="col-md-6 mb-5">
											<select v-model="prenda.idtipo" name="tipo" class="custom-select" required>
												<option value="" disabled selected>Tipo</option>
												<option v-if="user.perfil==3" value="12">Próximamente</option>
												<option v-for="(tipo, index) in tipos" :value="tipo.id" :key="index">
													{{tipo.nombre}}
												</option>
											</select>
										</div>
										<div class="col-md-12 mb-5">
											<label for="monto">Monto</label>
											<CurrencyInput v-model="prenda.precio" type="text" id="monto" name="monto" class="form-control" placeholder="Monto requerido" required />
										</div>
										<div class="col-md-12 mb-5">
											<textarea v-model="prenda.descripcion" name="desc" placeholder="Descripción" class="form-control" maxlength="1000" minlength="10" rows="5" required></textarea>
										</div>
									</div>
								</div>
								<div class="col-lg-7">
									<div class="row align-items-center mb-3">
										<div class="col-lg-4">
											<label>Agregar tus fotos</label>
										</div>
										<div class="col-lg-8">
											<div class="form-row">
												<div class="col-md-6 mb-3">
													<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto1" v-on:fileChange="uploadFilePrenda($event, '0')" style="height: 160px;">
														<img v-lazy="$filters.storageMin(arrayFotos['0'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['0']">
														<img src="../../assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
													</Upload>
												</div>
												<div class="col-md-6 mb-3">
													<div class="form-row">
														<div class="col-6 mb-2">
															<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto2" v-on:fileChange="uploadFilePrenda($event, '1')" style="height: 75px;">
																<img v-lazy="$filters.storageMin(arrayFotos['1'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['1']">
																<img src="../../assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
															</Upload>
														</div>
														<div class="col-6 mb-2">
															<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto3" v-on:fileChange="uploadFilePrenda($event, '2')" style="height: 75px;">
																<img v-lazy="$filters.storageMin(arrayFotos['2'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['2']">
																<img src="../../assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
															</Upload>
														</div>
														<div class="col-6 mb-2">
															<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto4" v-on:fileChange="uploadFilePrenda($event, '3')" style="height: 75px;">
																<img v-lazy="$filters.storageMin(arrayFotos['3'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['3']">
																<img src="../../assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
															</Upload>
														</div>
														<div class="col-6 mb-2">
															<Upload :class="{'bg-white d-flex align-items-center justify-content-center cursor-pointer border-dashed rounded-sm overflow-hidden':true}" name="foto5" v-on:fileChange="uploadFilePrenda($event, '4')" style="height: 75px;">
																<img v-lazy="$filters.storageMin(arrayFotos['4'])" class="img-fluid" alt="Agregar foto del bien" title="Foto de publicación" v-if="arrayFotos['4']">
																<img src="../../assets/image/cruz.webp" height="30" width="30" alt="Agregar foto del bien" title="Foto de publicación" v-else>
															</Upload>
														</div>
													</div>
												</div>
												<div style="color:rgb(221,44,0);">
													{{msjErrorFoto}}
												</div>
											</div>
										</div>
									</div>
									<div class="row align-items-center mb-3">
										<div class="col-lg-6">
											Elige la ubicación de tus activos
										</div>
										<div class="col-lg-6 text-right">
											<div class="white rounded overflow-hidden shadow">
												<Map :lat="prenda.lat" :lng="prenda.lng" style="height:300px;" class="img-fluid" @update:modelValue="mapaEvent($event)"/>
											</div>
										</div>
									</div>
									<div class="row align-items-center">
										<div class="col-lg-6">
											Al publicar una solicitud, estás de acuerdo y aceptas los términos y condiciones.
										</div>
										<div class="col-lg-6 text-right">
											<button type="submit" class="btn btn-inversion" :disabled="loadingPublicacion || !validateForm">
												<BtnLoading v-if="loadingPublicacion" />
												<span v-else>
													Publicar
												</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Upload = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Upload" */"@/components/Upload.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	import Map from "@/components/Map.vue"
	
	export default {
		components: {
			Map,
			BtnLoading,
			CurrencyInput,
			Upload
		},
		data(){
			return {
				validateForm: false,
				loadingPublicacion: false,
				prenda: {
					nombre: '',
					idcategoria: '',
					idtipo: '',
					precio: 0,
					descripcion: '',
					arrayFotos: [],
					lat: 0,
					lng: 0
				},
				msjErrorFoto: '',
			}
		},
		mounted(){
			this.getCategorias()
			this.getTipos()
			this.getLocation()
		},
		methods:{
			addPublicacion(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				if (this.prenda.arrayFotos.length<=0) {
					this.msjErrorFoto = 'Debes agregar al menos una foto.'
					window.toastr.error(this.msjErrorFoto, 'Error')
					return
				}
				this.loadingPublicacion = true
				this.$store.dispatch('addPublicacion', {
					nombre: this.prenda.nombre,
					precio: this.prenda.precio,
					descripcion: this.prenda.descripcion,
					idcategoria: this.prenda.idcategoria,
					idtipo: this.prenda.idtipo,

					arrayFotos: this.prenda.arrayFotos,
					fechaPublicada: Math.round(new Date().getTime()/1000.0),
					idusuario: this.user.idusuario,
					lat: this.prenda.lat,
					lng: this.prenda.lng,

					version: 'v2'
				}).then(response => {
					window.toastr.info('Activo envíado exitosamente para aprobación.', 'Aviso')
					this.$router.push({name: 'perfil.index'})
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al registrar usuario', 'Error')
					}
					return error
				}).then(() => {
					this.loadingPublicacion = false
				})
			},
			uploadFilePrenda(file, tipo){
				this.loadingFile = true
				var formData = new FormData()
				formData.append('images', file)
				formData.append('modulo', 'prendas')

				this.$store.dispatch('uploadFile', formData).then(response => {
					this.prenda.arrayFotos[tipo] = response.data[0]
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFile = false
				})
			},
			getCategorias(){
				this.$store.dispatch('getCategorias')
			},
			getTipos(){
				this.$store.dispatch('getTipos')
			},
			checkValidity(){
				if (!this.$refs.userForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userForm.checkValidity()
				}
			},
			mapaEvent(data){
				this.prenda.lat = data.lat
				this.prenda.lng = data.lng
			},
			getLocation(){
				if(!("geolocation" in navigator)) {
					return
				}
				navigator.geolocation.getCurrentPosition(pos => {
					var coords = pos.coords || {}
					this.prenda.lat = parseFloat(coords.latitude || 0)
					this.prenda.lng = parseFloat(coords.longitude || 0)
				}, err => {
					return err
				})
			}
		},
		computed:{
			categorias(){
				return this.$store.getters.categorias || []
			},
			tipos(){
				return this.$store.getters.tipos || []
			},
			user(){
				return this.$store.getters.currentUser || []
			},
			arrayFotos(){
				return (this.prenda.arrayFotos || [])
			}
		},
		watch:{
			'prenda':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		},
	}
</script>